import { Container } from '@finn/atoms';
import { SpacingWrapper } from '@finn/platform-modules';
import { ErrorBoundary } from '@finn/ui-components';
import { PrequalifyFormCosmicMetadata } from '@finn/ui-cosmic';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';
import { useForm } from 'react-hook-form';

import { parseToHtml } from '~/utils/html';

import CosmicModule from '../../CosmicModule';
import { FormInputs, initialValues, validationSchema } from './config';
import FormWrapper from './FormWrapper';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(4),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(16.5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  link: {
    ...theme.customTypography.ctaUnderline,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

interface IProps {
  data: PrequalifyFormCosmicMetadata;
}

export const PrequalifyForm: React.FunctionComponent<IProps> = ({ data }) => {
  const classes = useStyles();

  const methods = useForm<FormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const {
    formState: { isSubmitSuccessful },
  } = methods;

  return (
    <>
      <Container>
        <Box className={classes.wrapper}>
          <Box>
            <Typography
              className={classes.title}
              variant={(data.header_size.key as Variant) || 'h2'}
            >
              {parseToHtml(
                isSubmitSuccessful ? data.title_success : data.title
              )}
            </Typography>
            <Typography variant="body1" component="div">
              {parseToHtml(
                isSubmitSuccessful ? data.description_success : data.description
              )}
            </Typography>
          </Box>
          {!isSubmitSuccessful && (
            <Box>
              <FormWrapper methods={methods} cosmicData={data.form_type} />
            </Box>
          )}
        </Box>
      </Container>
      {isSubmitSuccessful && (
        <ErrorBoundary key={module.id}>
          <SpacingWrapper>
            <CosmicModule data={data.module_success} />
          </SpacingWrapper>
        </ErrorBoundary>
      )}
    </>
  );
};

export default PrequalifyForm;
