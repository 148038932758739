import { Checkbox, CheckboxProps, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { InputColor } from '~/types/general';

const useStyledCheckboxStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
interface IStyledCheckboxProps extends CheckboxProps {
  inputColor?: InputColor;
  error?: boolean;
}
const StyledCheckbox: React.FunctionComponent<IStyledCheckboxProps> = ({
  inputColor,
  error,
  ...rest
}) => {
  const classes = useStyledCheckboxStyles({
    color: inputColor,
    hasError: error,
  });

  return (
    <Checkbox
      {...rest}
      className={cn(classes.root, '!py-0', {
        '!text-red': error,
        '!text-black': !error,
      })}
    />
  );
};

interface IProps extends CheckboxProps {
  error?: boolean;
  inputColor?: InputColor;
}

const CustomCheckbox: React.FunctionComponent<IProps> = ({
  error,
  inputColor,
  ...rest
}) => {
  return (
    <StyledCheckbox
      color="default"
      inputColor={inputColor}
      disableRipple
      error={error}
      className={cn({ '!text-red': error })}
      {...rest}
    />
  );
};

export default CustomCheckbox;
