import { isValidPhone } from '@finn/ua-auth';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { CountryCode } from '~/types/localization';

export type FormInputs = {
  firstName: string | '';
  lastName: string | '';
  phoneNumber: string | '';
  email: string | '';
  street: string | '';
  zipCode: string | '';
  dateOfBirth: Date | null;
  prequalifyConsent: boolean | null;
  newsletterConsent: boolean | null;
};

export const createValidationSchema = () =>
  Yup.object().shape<FormInputs>({
    firstName: Yup.string().nullable().required('yup.required'),
    lastName: Yup.string().nullable().required('yup.required'),
    phoneNumber: isValidPhone('phoneNumber', CountryCode.US),
    email: Yup.string().email('yup.email').nullable().required('yup.required'),
    street: Yup.string().nullable().required('yup.required'),
    zipCode: Yup.string().nullable().required('yup.required'),
    dateOfBirth: Yup.date()
      .typeError('yup.validDate')
      .test('Valid date', 'yup.validDate', (value) => dayjs(value).isValid())
      .nullable()
      .required('yup.required'),
    prequalifyConsent: Yup.bool().oneOf([true]).required('yup.required'),
    newsletterConsent: Yup.bool().nullable(),
  });

export const validationSchema = createValidationSchema();

export const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  street: '',
  zipCode: '',
  dateOfBirth: null,
  prequalifyConsent: false,
  newsletterConsent: false,
};

export enum Fields {
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Street = 'street',
  ZipCode = 'zipCode',
  DateOfBirth = 'dateOfBirth',
  PrequalifyConsent = 'prequalifyConsent',
  NewsletterConsent = 'newsletterConsent',
}
