import { traceabilityHeadersBrowser } from '@finn/ui-utils';
import axios from 'axios';

export const callWebhookFromId = async (frontendId: string, payload) => {
  return await axios.post(
    `/api/webhook`,
    {
      id: frontendId,
      payload,
    },
    { headers: traceabilityHeadersBrowser() }
  );
};
